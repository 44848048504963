import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Badge, PageTitle } from "../components/display"
import { ArrowRightIcon } from "@heroicons/react/solid"
import { Pagination } from "../components/inputs"

const BlogEntry = ({
  href,
  title,
  description,
  date,
  image,
  timeToRead,
  categories = [],
}) => {
  return (
    <Link
      to={href}
      className="flex flex-wrap my-10 p-6 border border-silver-light rounded group hover:bg-silver-light cursor-pointer transition duration-150 ease-in-out"
    >
      <div className="w-full lg:w-1/2 order-1">
        <article
          itemScope
          itemType="http://schema.org/Article"
          className="flex flex-col justify-between h-full"
        >
          <div>
            {categories.map(({ title, slug }) => (
              <Badge key={slug}>{title}</Badge>
            ))}
            <header className="my-4 text-xl md:text-2xl font-bold">
              <h3>
                <Link to={href} itemProp="url">
                  <span itemProp="headline" className="font-display">
                    {title}
                  </span>
                </Link>
              </h3>
            </header>
            <small className="py-2 inline-block text-jet-light">{date}</small>
            <section className="mb-4 text-sm md:text-base leading-normal text-jet">
              <p itemProp="description">{description}</p>
            </section>
          </div>
          <div className="flex flex-row justify-between">
            <Link
              className="text-xs font-semibold text-jet-light group-hover:text-jet flex items-center"
              to={href}
            >
              <span>Read More</span>
              <ArrowRightIcon className="inline-block w-4 h-4 ml-2" />
            </Link>
            <p className="text-xs text-jet-light ">{`${timeToRead} min read`}</p>
          </div>
        </article>
      </div>
      <div className="w-full lg:w-1/2 order-0 lg:order-1 mb-8 md:mb-0 pl-0 md:pl-4">
        <Link to={href}>
          <GatsbyImage
            className="w-full h-80 object-cover rounded bg-jet-light mix-blend-luminosity transition duration-150 ease-in-out"
            image={image}
            alt={title}
          />
        </Link>
      </div>
    </Link>
  )
}

const BlogListPageTemplate = ({ data, location, pageContext }) => {
  const posts = data.allMarkdownRemark.nodes
  const category = data.categoryYaml
  const siteTitle = `${category.title}`

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo
          title={category.seo.title}
          description={category.seo.description}
        />
        <section className="container mx-auto px-4 py-20">
          <PageTitle>{siteTitle}</PageTitle>
          <p className="text-lg text-jet mb-48 w-full text-center">{`No posts related to ${category.title} found.`}</p>
        </section>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} />
      <section className="py-20">
        <div className="container px-4 mx-auto">
          <PageTitle>{siteTitle}</PageTitle>
          <div>
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              const description = post.frontmatter.description || post.excerpt
              const image = getImage(post.frontmatter.thumbnail)
              return (
                <BlogEntry
                  key={post.id}
                  href={`.${post.fields.slug}`}
                  title={title}
                  description={description}
                  date={post.frontmatter.date}
                  image={image}
                  timeToRead={post.timeToRead}
                  categories={post.frontmatter.categories}
                />
              )
            })}
          </div>
          <div className="w-full flex items-center justify-center">
            <Pagination
              previousPagePath={pageContext.previousPagePath}
              nextPagePath={pageContext.nextPagePath}
              currentPageNumber={pageContext.humanPageNumber}
              totalPageCount={pageContext.numberOfPages}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogListPageTemplate

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $categoryTitle: String!) {
    site {
      siteMetadata {
        title
      }
    }
    categoryYaml(title: { eq: $categoryTitle }) {
      title
      slug
      seo {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
      filter: {
        frontmatter: {
          draft: { eq: false }
          categories: { elemMatch: { title: { eq: $categoryTitle } } }
        }
      }
    ) {
      nodes {
        id
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories {
            title
            slug
          }
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }
    }
  }
`
